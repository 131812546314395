:root {
    --main-color: #6A99B2;
    --font-color: #293c44;
    --btn-color: #a1c36a;
    --btn-font-color: #fff;
}

.logout-text :hover {
    cursor: pointer;
    opacity: .7;
}

.col-center {
    align-content: center;
    text-align: center;
}

.big-header {
    height: 52px;
    background-color: #EFF2F5;
    line-height: 0px;
    padding: 0 40px;
    position: relative;
}

.big-header .logo {
    height: 60px;
    margin: 16px 24px 16px 0;
    float: left;
}

.big-header .tabs .icon {
    color: var(--main-color);
    font-size: 20px;
}

.main-container-title {
    text-align: left;
    padding: 0;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
}

.big-header .item-btn {
    height: 52px;
    width: 150px;
    border: 0;
    background-color: transparent;
    color: var(--font-color);
    font-weight: bold;
}

.big-header .item-btn-active {
    height: 52px;
    width: 150px;
    border: 0;
    background-color: #e6e8e8;
    color: var(--main-color);
    font-weight: bold;
    border-radius: 0;
}

.big-header .manage-btn {
    color: var(--main-color);
    font-size: 12px;
}

.big-header .manage-btn span {
    text-decoration: underline;
}

.formtype-dropdown ul,
.formtype-dropdown li {
    padding: 0;
}

.formtype-dropdown-btn {
    width: 100%;
    color: var(--font-color);
    background-color: #EFF2F5;
    font-size: 12px;
    border-radius: 0;
    font-weight: bold;
}

.formtype-dropdown-btn:hover {
    width: 100%;
    color: #fff;
    background-color: var(--main-color);
    font-size: 12px;
    border-radius: 0;
}

.main-container {
    padding: 30px 50px;
    background-color: #fff;
}

@media (max-width: 768px) {
    .big-header .item-btn,
    .big-header .item-btn-active  {
        width: 80px;
        font-size: 10px;
        padding: 5px;
    }
}