.search-form-container {
    width: 100%;
    background-color: #e9f1f5;
    padding: 10px 20px;
}

.search-form-container .form-label {
    margin: 0;
    padding: 0;
    border-radius: 1px;
}

.search-form-container .form-label input,
.search-form-container .form-label div {
    border-radius: 1px;
    font-size: 12px;
}

.search-form-container input[class*=ant-calendar-picker-input] {
    height: 24px;
}

.search-form-container .form-label > div {
    padding: 0;
}

.search-form-container .form-label label {
    font-size: 11px;
    color: #293c44;
}

.search-form-container .form-button {
    color: var(--btn-font-color);
    background-color: var(--btn-color);
    border: 0;
    border-radius: 1px;
    width: 70px;
}

.search-form-container .clear-search {
    padding: 0;
    margin: 0;
}

.search-form-container .clear-search .text {
    color: var(--main-color);
    font-size: 10px;
}

.search-form-container .clear-search .text span {
    text-decoration: underline;
}

.label {
    text-align: left;
}