.greyed-out {
    z-index: 9999;
    min-width: 100%;
    min-height: 100%;
    background: black;
    opacity: .51;
}

.table-css {
    margin: 20px;
    max-height: 85%
}