.notice {
    display: flex;
    align-items: stretch;
    margin-bottom: 20px;
    /* background: #EFF2F5; */
    border: 1px solid var(--main-color);
    border-radius: 3px;
    color: #293c44;
}

.notice-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 100px;
    border-right: 1px solid var(--main-color);
    font-weight: bold;
}

.notice-body {
    flex: 1 1 auto;
    min-height: 15px;
    max-height: 100px;
    overflow: auto;
    padding: 12px 15px;
    background: #fff;
    font-size: 13px;
    line-height: 1.4;
}

.notice-body .notice-item {
    position: relative;
    margin: 0;
    padding: 0 0 0 15px;
    color: #293c44;
    white-space: pre-wrap;
}
.notice-body .notice-item.red {
    color: #ce050f;
}
.notice-body .notice-item.orange {
    color: #d66d00;
}
.notice-body .notice-item.blue {
    color: #0260b1;
}
.notice-body .notice-item.green {
    color: #238a2b;
}
.notice-body .notice-item.black {
    color: #000;
}
.notice-body .notice-item.default {
    color: #293c44;
}
.notice-body .notice-item::before {
    content: '・';
    position: absolute;
    left: 0;
}

.notice-item + .notice-item {
    margin-top: 10px;
}

.notice-date {
    margin-left: 5px;
    font-size: 12px;
    color: #999;
}