.preloader {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: rgb(255,255,255);
    background-color: rgba(255,255,255, 0.6);
    overflow-x: hidden;
    transition: 0.5s;
    display: block
  }
  
  .preloader-hide {
    display: none
  }