.side-text {
    text-align: center;
    writing-mode: vertical-lr;
    text-orientation: upright;
    text-align: center;
    padding: 7px;
    margin-left: 25px;
}

.side-text-num {
    writing-mode: sideways-lr;
    text-orientation: sideways;
}

.operation-title {
	text-align: center;
    padding: 5px;
    font-size: 10px;
    font-weight: bold;
}

.operation-button-group {
    text-align: center;
    padding: 0 5px;
}

.operation-button[class*="primary"] {
    margin: 5px 0;
    font-size: 12px;
    color: var(--btn-color);
    background-color: var(--btn-font-color);
    border-color: var(--btn-color) ;
}

.operation-button[class*="primary"]:hover {
    background-color: var(--btn-color);
    color: var(--btn-font-color);
    border-color: var(--btn-color) ;
}

.operation-button[class*="default"] {
    margin: 5px 0;
    font-size: 12px;
}

.button-download {
    background-color: var(--btn-color);
    color: var(--btn-font-color);
    border-color: var(--btn-color);
    font-weight: bold;
    font-size: 13px;
    align-items: center;
}

.button-download:hover {
    background-color: var(--btn-color);
    color: var(--btn-font-color);
    border-color: var(--btn-color);
    opacity: .8;
}

.operation-button[class*="primary"][disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
}

.side-container {
    height: 250px;
    display: flex;
}

.left-col-1 {
    width: 25x;
    min-height: 200px;
    max-height: 200px;
    background-color: rgb(215, 223, 245);
}

.right-col-1 {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    background-color: rgb(237, 240, 248);
    padding: 15px;
    min-height: 200px;
    max-height: 200px;
}

.left-col-1:before {
    content: "";
    position: relative;
    top: 200px;
    left: 0px;
    border-style: solid;
    border-width: 25px 25px 0;
    border-color: rgb(215, 223, 245) transparent;
    display: block;
    width: 0;
    z-index: 800;
}

:global .ant-layout-sider-trigger {
    bottom: 20px;
}
