.login-title {
    font-weight: bold;
    font-size: 16px;
    color: lightgray;
    margin-bottom: 20px;
}

.loginForm {
    min-width: 30vw;
}

.loginForm .primary-btn {
    background-color: var(--btn-color);
    font-size: 12px;
    width: 150px;
    color: var(--btn-font-color);
    border-color: var(--btn-color);
}

.loginForm .primary-btn:hover {
    background-color: var(--btn-color);
    color: var(--btn-font-color);
    opacity: .7;
}

.loginForm .primary-btn:disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.loginForm .link-btn span {
    font-size: 12px;
    color: var(--font-color);
}

.loginForm .link-btn:hover span {
    color: var(--font-color);
    text-decoration: underline;
    opacity: .7;
}

.loginForm .link-btn a {
    padding: 0;
}

.login-text {
    margin: auto;
    font-size: 12px;
}

.forgot-title {
    font-size: 12px;
    margin: 15px;
    color: var(--font-color);
}

.pwreset-form {
    font-size: 12px;
}

.pwreset-form-label {
    margin: 20px 0 0;
    font-size: 12px;
    color: var(--main-color);
    font-weight: bold;
    height: 20px;
    line-height: 20px;
}

.pwreset-form-label i {
    margin-right: 5px;
}
