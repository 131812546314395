.field_name {
    font-weight: bold;
}

.field_content {
    background-color: white;
    border: solid rgb(202, 202, 202) 1px;
    border-radius: 3px; 
    color: rgb(26, 26, 26);
    padding-left: 5px;
    min-height: 21px;
}
