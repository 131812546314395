:global .ant-menu-submenu-selected {
    /* background-color: #d8e6ed; */
}

:global .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #d8e6ed;
    margin: 0px 0 2px 0;
}

:global .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    padding-left: 5px;
    background-color: #f0f5f8;
    margin: 2px 0px 0px 0px;
}

:global .ant-menu-submenu-selected {
    color: #112631;
    font: 500;
}

:hover

:global .ant-menu-item-selected {
    color: #112631;
    font-weight: 500;
}

:global .ant-menu-inline > .ant-menu-item {
    text-align: left;
}

:global .ant-menu-item:not(:last-child) {
    padding-left: 12px;
}

:global .ant-menu .ant-menu-light .ant-menu-root .ant-menu-inline {
    background-color: crimson;
}

:global .ant-menu-item:not(:last-child) {
    margin-bottom:0px;
}

:global .ant-menu-inline .ant-menu-submenu-title {
    margin-top: 1px;
}

:global .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 3px;
}

/* Members */
.members-content {
    background-color: #fff;
    text-align: left;
}

.members-container header {
    background-color: #fff;
    text-align: left;
    padding: 0;
    font-size: 16px;
    font-weight: bold;
}

.members-content-header {
    margin: 0 24px;
    padding: 5px 10px;
    font-weight: bold;
    border-bottom: solid 1px;
    border-left: solid 1px;
}

.members-content-add {
    padding: 24px;
}

.members-content-add p {
    margin: 0;
    font-size: 11px;
    color: var(--font-color);
}

.members-content-add input {
    border-radius: 0;
    width: 320px;
}

.members-content .form-label {
    margin: 0;
}

.members-content .form-label > div {
    padding: 0;
}

.members-content-table {
    background-color: #fff;
    padding: 0 16px;
    margin: 0;
    min-height: 280px;
}

.members-content-table div {
    border: 0;
    font-size: 12px;
}

.members-content-table thead tr span {
    color: var(--main-color);
    font-weight: bold;
    font-size: 10px;
}

.table-tag {
    width: 90px;
    text-align: center;
    border: 0;
    border-radius: 2px;
    font-size: 10px;
}

.members-content-footer {
    text-align: center;
    background-color: #fff;
}

.members-content-footer button {
    height: 40px;
}

.members-content-button,
.members-content-button:active,
.members-content-button:focus {
    border-radius: 2px;
    border-color: var(--btn-color);
    background-color: var(--btn-color);
    color: var(--btn-font-color);
}

.members-content-button:hover {
    border-radius: 2px;
    border-color: var(--btn-color);
    background-color: var(--btn-color);
    color: var(--btn-font-color);
    opacity: .7;
}

.leftmenu {
    background-color: #fff;
    border: 0;
}

li.leftmenu-item {
    background-color: #e9f1f5;
    font-weight: bold;
    text-align: left;
}

.leftmenu li.leftmenu-item > div {
    font-size: 12px;
}

li.leftmenu-item-child {
    text-align: left;
}

.leftmenu-popover {
    line-height: 40px;
    border: 0;
}

.leftmenu-popover-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.leftmenu-popover-button {
    color: var(--font-color);
}

.setting-modal-header {
    font-size: 16px;
    padding: 15px;
    text-align: center;
    font-weight: bold;
}

.setting-modal-content {
    padding: 20px;
    font-weight: bold;
}

.setting-modal-footer {
    text-align: center;
    padding: 20px;
}

.setting-modal-footer button {
    padding: 0 40px;
}

.setting-modal-cancel {
    font-size: 12px;
    color: var(--font-color);
}

button.setting-modal-cancel span {
    text-decoration: underline;
}

/* User */
.user-drawer {
    padding: 0 20px;
    font-size: 14px;
}

.user-drawer-title {
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
}

.user-drawer .input-form > div {
    padding: 0;
}

.user-drawer .input-form label {
    margin: 0;
    font-size: 11px;
    color: var(--font-color);
}

.user-drawer button,
.user-drawer button:active,
.user-drawer button:focus {
    border-radius: 2px;
    border-color: var(--btn-color);
    background-color: var(--btn-color);
    color: var(--btn-font-color);
    margin-top: 30px;
}

.user-drawer button:hover {
    border-color: var(--btn-color);
    background-color: var(--btn-color);
    color: var(--btn-font-color);
    opacity: .7;
}
