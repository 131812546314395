.itemlist-table div {
    border: 0;
    font-size: 12px;
}

.itemlist-table table thead > tr > th > span {
    display: block !important;
    text-align: center;
    line-height: 1.2;
}

.itemlist-table thead tr span {
    color: var(--main-color);
    font-weight: bold;
    font-size: 10px;
}

.itemlist-table .text {
    text-align: center;
    word-wrap: break-word;
    word-break: break-word;
    line-height: 1.3;
}

.itemlist-table tr > td:first-child .text ,
.itemlist-table tr > td[class = "ant-table-selection-column"] + td .text {
    white-space: nowrap;
}

.itemlist-table-tag {
    min-width: 50px;
    max-width: 80px;
    width: 100%;
    text-align: center;
    border: 0;
    border-radius: 1px;
    font-size: 10px;
    margin-right: 0;
}

.dellist {
    margin: 10px 0;
    padding: 10px;
    background: #f8f8f8;
    font-size: 12px;
}

.dellist > div[class*="ant-list-header"] {
    padding: 0;
    font-weight: bold;
    margin-bottom: 5px;
}

.dellist li {
    padding: 2px 5px;
}

.dellist-id {
    display: inline-block;
    min-width: 25px;
}

.dellist-type {
    margin-right: 5px;
}

.dellist-id {
    display: inline-block;
    width: 30px;
}

.delete-btn {
    display: block;
    margin: 0 auto;
    background: var(--btn-color);
    color: #fff;
    border: none;
    font-size: 12px;
    line-height: 32px;
}
.delete-btn:hover,
.delete-btn:active {
    background: var(--btn-color);
    color: #fff;
    border: none;
    opacity: 0.8;
}