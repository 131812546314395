.field_container {
    /* border: dotted lightblue 1px; */
    overflow:  auto;
}

.layout {
	background-color: #ffffff;
}

.status-arrow-first,
.status-arrow {
    display: block;
    float: left;
    width: 100px;
	height: 30px;
	background: var(--border-color);
	text-align: center;
	position: relative;
	margin: 0 15px 0 2px;
	font-size: 12px;
	text-decoration: none;
	color: rgb(0, 0, 0);
	line-height: 30px;
}

.status-arrow:before {
    content: "";
	border-top: 15px solid var(--border-color);
	border-bottom: 15px solid var(--border-color);
    border-left: 15px solid transparent;
	position: absolute; left: -15px; top: 0;
}

.status-arrow-first:after,
.status-arrow:after {
    content: "";
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-left: 15px solid var(--border-color);
	position: absolute; right: -15px; top: 0;
}

.grid-container {
	padding: 0;
	margin: 0;
	list-style: none;
	border: 1px solid silver;
	-ms-box-orient: horizontal;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -moz-flex;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}

.input-container {
    display: flex;
    flex-direction: row;
    margin: 10px 0;
}

.label {
    min-width: 100px;
    max-width: 100px;
    font-size: 10px;
    font-weight: bold;
	line-height: 32px;
}

.input-container input,
.input-container textarea,
.input-container button,
.input-container div {
    border-radius: 1px;
}

.radio-input {
    color: var(--main-color);
    font-size: 10px;
    border-radius: 1px;
}

.checkbox {
    color: var(--main-color);
    font-size: 10px;
	text-align: left;
	line-height: 32px;
}


.input-container-upload,
.input-container-upload > span,
.input-container-upload div {
	width: 100%;
}

.file-input {
	font-size: 12px;
	width: 100%;
}

.download-btn {
	font-size: 12px;
}

.download-btn span {
	text-decoration: underline;
}

.right-input-1,
.right-input-2,
.right-input-3,
.right-input-4 {
	padding: 20px 20px 20px 0;
}

/* .left-input-1:before {
    content: "";
    position: relative;
    top: 500px;
    border-style: solid;
    border-width: 25px 25px 0;
    border-color: rgb(215, 223, 245) transparent;
    display: block;
    z-index: 800;
} */

/*
.left-input-2:before {
	content: "";
    position: relative;
	top: 277px;
	width: 40px;
    border-style: solid;
    border-width: 25px 25px 0;
    border-color: #D3F1EF transparent;
	display: block;
	left: -20px;
    z-index: 800;
} */

.side-box {
	display: flex;
	flex-direction: column;
	font-size: 14px;
	font-weight: bold;
}

.side-box-text {
    text-align: center;
    writing-mode: vertical-lr;
    text-orientation: upright;
	margin: 0 auto 20px;
}

.side-box-num {
	text-align: center;
	margin: 50px 0 10px;
}

.uploadFile {
	width: 100%;
}

.drawer-footer-btn {
	margin-top: 20px;
}

.drawer-footer-btn button {
	width: 100px;
}

.drawer-footer-btn button span {
	font-size: 12px;
}

.save-btn,
.save-btn:active,
.save-btn:focus {
	color: var(--btn-font-color);
	background-color: var(--btn-color);
	border-color: var(--btn-color);
}

.save-btn:hover {
	color: var(--btn-font-color);
	background-color: var(--btn-color);
	border-color: var(--btn-color);
	opacity: .7;
}


.verticalCheckbox {
    display: block;
    margin-right: 0px;
}

.verticalCheckbox label {
    margin-left: 0px !important;
}