.mainWrapper {
    margin: 0px;
}

.form-container {
    width: 700px;
    margin: auto;
}

.form-step-container {
    display: flex;
    flex-direction: row;
}

.form-spacing {
    min-width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.center-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.side-text {
    text-align: center;
    writing-mode: vertical-rl;
    text-orientation: upright;
}

.left-col-num {
    font-size: 16px;
    font-weight: bold;
    margin-left: -10px;
    text-align: center;
    margin-bottom: 10px;
}

.left-col-text {
    font-size: 16px;
    font-weight: bold;
    margin-left: -12px;
    text-orientation: upright;
    writing-mode: vertical-rl;
}

.left-col-1,
.left-col-2,
.left-col-3 {
    width: 50px;
    padding-top: 20px;
    border-style: solid;
    border-width: 25px 25px 0;
    border-color: rgb(255, 255, 255) transparent;
}

.left-col-1 {
    color: #5C6FB9;
    background-color: rgb(215, 223, 245);
}

.left-col-2 {
    margin-top: 2px;
    color: #61B99E;
    background-color:  rgb(211, 241, 239);
}

.left-col-3 {
    margin-top: 2px;
    color: #80AF85;
    background-color: rgb(212, 241, 217);
}

.left-col-1:before,
.left-col-2:before,
.left-col-3:before {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 25px 25px 0;
    width: 0;
    z-index: 800;
    top: -28px;
    left: -25px;
}

.left-col-1:before {
    top: -25px;
    border-color: rgb(215, 223, 245) transparent;
}

.left-col-2:before {
    border-color: rgb(215, 223, 245) transparent;
}

.left-col-3:before {
    border-color: rgb(211, 241, 239) transparent;
}


.right-col-1,
.right-col-2,
.right-col-3 {
    width: calc(100% - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px;
}

.right-col-1 {
    background-color: rgb(237, 240, 248);
}

.right-col-2 {
    margin-top: 3px;
    background-color: rgb(237, 248, 247);
}

.right-col-3 {
    margin-top: 3px;
    background-color: rgb(237, 248, 239);
}

.uploadFile {
    width: 100%;
}

.submitButton {
    background-color: var(--btn-color);
    margin: 50px 250px;
    width: 200px;
    height: 50px;
    color: white;
}

.submitButton:hover {
    background-color: var(--btn-color);
    color: white;
    opacity: .7;
}

.title {
    text-align: center;
    padding: 0;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
}

.title-helper {
    text-align: center;
    padding: 0;
    font-size: 12px;
    margin-top: 5px;
    opacity: .7;
}

.input-container input,
.input-container textarea,
.input-container button,
.input-container div {
    border-radius: 1px;
}

.asterisk {
    color: red;
    font-size: 18px;
    font-weight: bold;
    min-width: 15px;
    max-width: 15px;
}

.date-input {
    width: 300px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    padding: 2px;
}

.file-input {
	font-size: 12px;
	width: 100%;
}

.input-container {
    display: flex;
    margin: 10px 0;
}

.label {
    min-width: 100px;
    max-width: 100px;
    font-size: 11px;
    font-weight: bold;
    text-align: right;
    line-height: 32px;
}

.input-container input {
    border-radius: 0;
}

.radio-input {
    color: var(--main-color);
    font-size: 11px;
}

.checkbox {
    color: var(--main-color);
    font-size: 11px;
    text-align: left;
	line-height: 32px;
}

.input-validation {
    color: darkgrey;
}

.phone-inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: space-between;
}

.textarea_s::placeholder {
    font-size: 10px !important;
}

.verticalCheckbox {
    display: block;
    margin-right: 0px;
}

.verticalCheckbox label {
    margin-left: 0px !important;
}