.small-header {
    height: 30px;
    background-color: #010133;
    color: lightgray;
    font-weight: bold;
    font-size: 14px;
    line-height: 28px;
}

.logout-text:hover {
    cursor: pointer;
    opacity: .7;
}

.big-header {
    height: 60px;
    background-color: lightgray;
    color: black;
    line-height: 14px;
}

.col-center {
    align-content: center;
    text-align: center;
}